import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import { VscDebugBreakpointData } from 'react-icons/vsc'


import imgDefault from '../../assets/image/placeholder.png'



function BlockF1({ image1, image2, image3, title, text, listsAbout, listsServices, sloganPrincipal }) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <>
            {/* <section className="w-4/5 mx-auto md:py-32 py-10">
                <div className="md:flex md:flex-row flex flex-col md:p-0 px-2 content-reverse">
                    <div className="md:w-[50%] relative">
                        <div
                            className="md:w-full md:md:h-[600px] w-[300px] h-[300px] bg-cover bg-center rounded-full"
                            style={{ backgroundImage: `url("${image1 ? image1 : imgDefault}")` }}
                        ></div>
                        <div
                            className="w-[50%] h-[50%]  absolute bottom-0 right-0 border-white border-[15px] z-10 bg-cover bg-center rounded-full"
                            style={{ backgroundImage: `url("${image2 ? image2 : imgDefault}")` }}
                        ></div>
                        <div
                            className="w-[50%] h-[50%]  absolute md:bottom-[-100px] md:block hidden md:right-[210px] border-white border-[15px] z-10 bg-cover bg-center rounded-full"
                            style={{ backgroundImage: `url("${image3 ? image3 : imgDefault}")` }}
                        ></div>
                    </div>

                    <div className="md:w-[50%] py-4 md:px-8 px-3 self-center md:text-start text-center">
                        {
                            sloganPrincipal ?
                                rpdata?.dbPrincipal?.licensed.length > 1 ?
                                    <h2 className="separator-about capitalize">
                                        {rpdata?.dbPrincipal?.licensed}
                                    </h2>
                                    : <h2 className="separator-about pb-3 capitalize">we have {rpdata?.dbPrincipal?.exprYears} years of experience </h2>
                                :
                                <h2 className="separator-about capitalize">
                                    {
                                        title ? title :
                                            <span className='lowercase text-[18px]'>
                                                {`default title={'frase'}`} o
                                                {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                            </span>
                                    }
                                </h2>
                        }
                        <p className="pb-4">
                            {
                                text ? text :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>
                        {
                            listAbout ?
                                <ul className='grid grid-cols-1 md:grid-cols-2 pb-5'>
                                    {
                                        rpdata?.dbAbout?.[0].list.length > 1 ?
                                            rpdata?.dbAbout?.[0].list.map((item, index) => {
                                                return (

                                                    <li key={index} className="py-2 flex items-center">
                                                        <VscDebugBreakpointData />
                                                        <span className="pl-2">{item}</span>
                                                    </li>
                                                )
                                            })
                                            : null
                                    }
                                </ul>
                                : null
                        }
                        {
                            listServices ?
                                <ul className="grid grid-cols-1 md:grid-cols-2 pb-5">
                                    {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
                                        return (
                                            <li key={index} className="py-1 flex items-center">
                                                <VscDebugBreakpointData />
                                                <span>{item.name}</span>
                                            </li>
                                        )
                                    })}
                                </ul>
                                : null
                        }

                        <ButtonContent btnStyle='three' />

                        <div className="w-full">
                            <span className="separator-about"></span>
                        </div>
                    </div>

                </div>
            </section> */}

            <section className="flex justify-center flex-col md:flex-row items-center space-x-0 w-[100%] mx-auto py-10 fesbg lg:px-10 mb-16 lg:mb-20">
                <div className="hidden lg:flex w-[25%] h-[600px] bg-center bg.bg-cover justify-end items-center" data-aos="fade-right" data-aos-duration="1900">
                    <img
                        src={image2 ? image2 : imgDefault}
                        alt='no found'
                        loading='lazy'
                        className=' z-10 w-[90%] h-[300px] md:h-[70vh]  object-cover rounded-tl-3xl rounded-bl-3xl'
                    />

                </div>
                <div className="flex-col w-[90%] lg:w-[40%] h-auto lg:h-[1100px] bg-center bg-cover justify-end items-center relative bg-[#FDF9F3] rounded-tl-[50px] rounded-br-[50px] pt-5 px-3"
                    style={{ boxShadow: "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em" }}
                >
                    <div data-aos="fade-up" data-aos-duration="1900" className="w-[100%] h-[300px] lg:h-[570px] bg-center bg-cover relative rounded-[15%]"
                        style={{ backgroundImage: `url("${image1 ? image1 : imgDefault}")` }}></div>
                    <div className="w-full h-auto p-6 text-center flex-col mx-0 justify-center ">
                        {
                            sloganPrincipal ?
                                rpdata?.dbPrincipal?.licensed.length > 1 ?
                                    <h2 className="separator-about capitalize">
                                        {rpdata?.dbPrincipal?.licensed}
                                    </h2>
                                    : <h2 className="separator-about pb-3 capitalize">we have {rpdata?.dbPrincipal?.exprYears} years of experience </h2>
                                :
                                <h2 className="separator-about capitalize">
                                    {
                                        title ? title :
                                            <span className='lowercase text-[18px]'>
                                                {`default title={'frase'}`} o
                                                {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                            </span>
                                    }
                                </h2>
                        }
                        <p className="pb-4">
                            {
                                text ? text :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>
                        {
                            listsAbout ?
                                <ul className='grid grid-cols-1 md:grid-cols-2 pb-5'>
                                    {
                                        rpdata?.dbAbout?.[0].list.length > 1 ?
                                            rpdata?.dbAbout?.[0].list.map((item, index) => {
                                                return (

                                                    <li key={index} className="py-2 flex items-center">
                                                        <VscDebugBreakpointData />
                                                        <span className="pl-2">{item}</span>
                                                    </li>
                                                )
                                            })
                                            : null
                                    }
                                </ul>
                                : null
                        }
                        {
                            listsServices ?
                                <ul className="grid grid-cols-1 md:grid-cols-2 pb-5">
                                    {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
                                        return (
                                            <li key={index} className="py-1 flex items-center">
                                                <VscDebugBreakpointData />
                                                <span>{item.name}</span>
                                            </li>
                                        )
                                    })}
                                </ul>
                                : null
                        }

                        <ButtonContent btnStyle='three' />
                    </div>
                </div>
                <div data-aos="fade-left" data-aos-duration="1900" className=" hidden lg:flex w-[25%] h-[600px] bg-center bg.bg-cover justify-start items-center   ml-24">
                    <img
                        src={image3 ? image3 : imgDefault}
                        alt='no found'
                        loading='lazy'
                        className=' z-10 w-[90%] h-[300px] md:h-[70vh]  object-cover rounded-tr-3xl rounded-br-3xl '
                    />

                </div>
            </section>
        </>
    );
}

export default BlockF1;
